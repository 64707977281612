$(document).ready(function() {
  'use strict';

});

function contactRecaptchaCallback() {

    $("#contact-form").validate({
      rules: {
        name: "required",
        firstname: "required",
        companyname: "required",
        country: "required",
        email: "required",
        phone: "required",
        subject: "required",
        message: "required"
      },
      errorPlacement: function(error, element) {
        error.appendTo("");
      }
    });

    if ($("#privacy").is(":checked") == true) {

    if ($("#contact-form").valid() == true) {
    $.ajax({
          type: $("#contact-form").attr('method'),
          url: $("#contact-form").attr('action'),
          data: $("#contact-form").serialize(),
          beforeSend: function(data) {
            $("#contact-form").find(':input').prop('disabled', true);
          },
          success: function(data) {
            if (data.success) {
              showMessageBox('success', msg['MSG_MESSAGE_SENT']);
              $("#contact-form").find(':input').prop('disabled', false);
            } else {
              showMessageBox('danger', data.errors.message);
            }
          },
          failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          },
          complete: function(data) {
            grecaptcha.reset();
          }
        })

    } else {
      grecaptcha.reset();
    }

  } else {
    grecaptcha.reset();
    showMessageBox("danger", "Devi accettare la privacy policy")
  }
}

