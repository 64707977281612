$(document).ready(function() {

    $('#mobile-menu-trigger').on('click', function () {
        $(this).children('img').toggleClass('hidden');
        $('.mobile-menu, .mobile-menu__search').fadeToggle();
        $('body').toggleClass('overflow-hidden');
    });

    $('.mobile-menu__language').on('click', function () {
        $(this).siblings('ul').slideToggle();
    });

    /*var delay=1000, setTimeoutConst;
    $('.main-menu ul li').hover(function(){
        $('.main-menu ul li:hover .secondary-menu').addClass('hide');
            setTimeoutConst = setTimeout(function(){
                $('.main-menu ul li:hover .secondary-menu').removeClass('hide');
            }, delay);
    },function(){
            clearTimeout(setTimeoutConst );
    })*/

    function wait(ms)
        {
        var d = new Date();
        var d2 = null;
        do { d2 = new Date(); }
        while(d2-d < ms);
        }

});