$(document).ready(function() {
    'use strict';

    loadQtyInputs();

    for (let product of $('.product-container').toArray()) {
        calculateTotal(product);
    }

    $('.qty-max').on('click', function () {
        const $this = $(this);
        const inputName = $this.attr('data-input');
        const input = $(`.i-number[name="${inputName}"]`);
        const max  = input.data('max') >> 0;

        input.val(max).trigger('input');
    });

    $('#qty-max-all').on('click', function () {
        $('.qty-max').each(function () {
            $(this).trigger('click');
        });
    });

    $('.add-to-wishlist-button').on('click', function (event) {
        event.preventDefault();

        const form = $(this).closest($('.addtocart-form'));
        $.ajax({
            type: "POST",
            url: "/restful/shopping/public/checkout/addtocart?wishlist=true",
            data: form.serialize(),
            headers: {'Accept': 'application/json'},
            success: function(data) {
                if (!data.success) {
                    if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code == 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    }
                    else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                        showMessageBox('danger', msg['MSG_RULE_REJECT'].replace(/:rule/g, data.rejectCartRuleName))
                    }
                    else if (data.code == 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                }
                else {
                    showMessageBox('success', msg['MSG_ADDEDTOWISHLIST']);
                    
                    if (window.dataLayer) {
                        const modelId = form.find('[name*="qty_model"]').attr('name').replace('qty_model_', '');
                        dataLayer.push({ ecommerce: null });
                        dataLayer.push({
                            event: 'add_to_wishlist',
                            ecommerce: window.ecommerce[modelId]
                        });
                    }
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.addtocart-form').on('submit', function (event) {
        event.preventDefault();
        var form = $(this).closest($('.addtocart-form'));
        $.ajax({
            type: "POST",
            url: "/restful/shopping/public/checkout/addtocart",
            data: form.serialize(),
            headers: {'Accept': 'application/json'},
            success: function(data) {
                if (!data.success) {
                    if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code == 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    }
                    else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                        showMessageBox('danger', msg['MSG_RULE_REJECT'].replace(/:rule/g, data.rejectCartRuleName))
                    }
                    else if (data.code == 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                }
                else {
                    rewixUpdateCart(form);
                    updateCartCount(form);
                    showMessageBox('success', msg['MSG_ADDEDTOCART']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });
});


function updateCartCount(form, callback) {
    $('.header-utilities__cart').parent().load(window.location.href + ' .header-utilities__cart:lt(1)', function() {
        if (callback) {
            callback();
        }
    });
}


function calculateTotal(product) {
    const $product = $(product);

    // Calculate availability total
    var availabilityTotal = 0;

    $product.find('.availability').each(function () {
        availabilityTotal += parseInt($(this).text());
    });

    $('#availability-total').text(availabilityTotal);


    // Calculate order total
    var orderTotal = 0;

    $product.find('.i-number').each(function () {
        orderTotal += parseInt($(this).val());
    });

    $('#order-total').text(orderTotal);

    if (orderTotal > 0) {
        $product.find('.add-to-cart-button').prop('disabled', false);
        $product.find('.add-to-wishlist-button').prop('disabled', false);
    }
    else {
        $product.find('.add-to-cart-button').prop('disabled', true);
        $product.find('.add-to-wishlist-button').prop('disabled', true);
    }


    // Calculate price total
    var singlePrice = parseFloat($('#price').text()).toFixed(2);
    var priceTotal = parseFloat(orderTotal * singlePrice).toFixed(2);
    $('#price-total').text(priceTotal + "€");
}

function loadQtyInputs() {
    $('.i-number').each(function() {
        const $this = $(this);

        if (!$this.data('initialized')) {
            const max = $this.data('max') >> 0;
            const min = $this.data('min') >> 0;
            const initValue = $this.val();
            let restoreColor = () => {};
    
            if (initValue > max || initValue < min) {
                $this.css('color', 'red');
                restoreColor = () => $this.css('color', '');
            }
    
            $('<button class="i-number-btn i-number-minus" tabindex="-1"><img src="/skins/current-skin/images/profile/qty-minus.svg"/></button>')
                .insertBefore($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });
            
            $('<button class="i-number-btn i-number-plus" tabindex="-1"><img src="/skins/current-skin/images/profile/qty-plus.svg"/></button>')
                .insertAfter($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });
    
            $this.on('keydown input', function(event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);
    
            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }
    
            function checkValue(value) {
                if (value > max) {
                    $this.val(max);
                }
                else if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                restoreColor();
                calculateTotal($this.closest('.product-container'));
            }
        }
    });
}