$(document).ready(function() {
    'use strict';

    $('img').bind("contextmenu", function(e) {
        e.preventDefault();
    });

    $('.product-item__img').bind("contextmenu", function(e) {
        e.preventDefault();
    });
    
    $('.product-item__link').bind("contextmenu", function(e) {
        e.preventDefault();
    });


    // catalog layout
    function checkPreferredLayout() {
        var layout = localStorage.getItem('catalogLayout');
        if (layout && layout === 'list') {
            $('#list-option').prop('checked', true).trigger('click');
        } else {
            layout = 'grid';
            $('#grid-option').prop('checked', true).trigger('click');
        }

        setCatalogLayout(layout);
    }

    function setCatalogLayout(layout) {
        if (layout === 'grid') {
            $('#catalog-wrapper .list-layout').hide();
            $('#catalog-wrapper .grid-layout').fadeIn();
        } else {
            $('#catalog-wrapper .grid-layout').hide();
            $('#catalog-wrapper .list-layout').fadeIn();
        }

        localStorage.setItem('catalogLayout', layout);
    }




    // catalog filters
    $('#catalog-container').on('click', '.catalog-filter', function(event) {
        event.preventDefault();
        var url = $(this).attr('href');
        reloadCatalog(url, true);
    });

    function reloadCatalog(url, push) {
        $('#catalog-container').addClass('catalog-loading');
        $.ajax({
            url: url,
            type: "GET",
            dataType: "html",
            complete: function(jqXHR, textStatus){
                var response = jqXHR.responseText;

                $('#catalog-container').html($("<div>").append($.parseHTML(response)).find("#catalog-wrapper"));

                if (push) {
                    window.history.pushState(null, document.title, url);
                }
                populateRemoveFilters();
                checkPreferredLayout();
                loadQtyInputs();
                addClickEvent();
                $('.filters-wrapper').show();
                $('#catalog-container').removeClass('catalog-loading');
                $('html, body').removeClass('is-popup-open');
            }
        });
    }

    $('#catalog-container').on('click', '.filters-wrapper a', function(event) {
        event.preventDefault();
        var url = $(this).attr('href');
        reloadCatalog(url, true);
    });

    function populateRemoveFilters() {
        $('.filters-wrapper li.active > a').each(function() {
            var id = $(this).data('ref');
            var url = $(this).attr('href');
            $(document.getElementById(id)).attr('href', url);
        });
        $('.filters-wrapper > a').each(function() {
            if ($(this).attr('href') === '#') {
                $(this).addClass('disabled');
                $(this).children('span.fa').remove();
            }
        });
    }

    function addClickEvent() {
        $('#filters-trigger').on('click', function () {
            $('.filters-wrapper').slideToggle();
            $('body').toggleClass('open-filter');
        });

        $('.dropdown-slide').on('click', function () {
            $(this).siblings('.subfilters-list').slideToggle();
            $(this).children('img').toggleClass('hide');
        });

        $('#filters-checkbox-trigger').on('click', function () {
            $('.filters-checkbox').toggleClass('open');
            $(this).children('img').toggleClass('hide');
        });

        $('#layout-options input[type="radio"]').on('click', function() {
            setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
        });
    }



    populateRemoveFilters();
    checkPreferredLayout();
    loadQtyInputs();
    addClickEvent();
});