$(document).ready(function() {


    $('.carrier-form').change(function() {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    if (document.getElementsByClassName('counter').length > 0) {
        showMessageBox('danger', msg['ERR_QUANTITYWISHLIST']);
    }

    // update cart quantity
    $('.cart-update').submit(function(event) {
        event.preventDefault();

        const $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function(data) {
                rewixUpdateCart($form);
                window.location.href = '/current/cart';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.wishlist-update').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            success: function(data) {
                location.reload(true)
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    $('.wishlist-select-all').change(function() {
        if(this.checked) {
            $('#' + this.name + " INPUT[type='checkbox']").each(function() {
                $( this ).prop("checked", true);
            });
        } else {
            $('#' + this.name + " INPUT[type='checkbox']").each(function() {
                $( this ).prop("checked", false);
            });
        }       
    });



    $('.wishlist-update-all').on('click', function(event) {
        var products = document.getElementsByClassName('wishlist-update').length;
        var i = 0;
        $('.wishlist-update').each(function(event) {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    i++;
                    if (i == products) {
                        window.location.reload(true);
                    }
                },
                error: function(xhr, textStatus, errorThrown) {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            });
        });
    });

    $('.btn-reload').on('click', function(event) {
        window.location.reload(true);
    });

    $('.wishlist-select-all-products').on('click', function(event) {

        $('.wishlist-update INPUT[type="checkbox"]').each(function() {
            $( this ).prop("checked", true);
        });

        $('.wishlist-select-all-products').addClass('hide');
        $('.wishlist-deselect-all-products').removeClass('hide');

    });

    $('.wishlist-deselect-all-products').on('click', function(event) {

        $('.wishlist-update INPUT[type="checkbox"]').each(function() {
            $( this ).prop("checked", false);
        });

        $('.wishlist-deselect-all-products').addClass('hide');
        $('.wishlist-select-all-products').removeClass('hide');

    });


    // remove cart size
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        let data = {};
        data[$this.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const container = $this.closest('.qty-input-container');
                    rewixUpdateCart(container, true);
                    window.location.href = '/current/cart';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
    
    $('.btn-wishlist-remove').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        let data = {};
        data[$this.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/profile/wishlist';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // remove cart item
    $('.btn-cart-remove-all').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        const currentTableSelector = $this.attr('data-product-id');
        const currentTable = $(currentTableSelector);
        const inputs = currentTable.find('.i-number');

        let data = {};
        inputs.each(function () {
            data[$(this).attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const container = $this.closest('.cart-update');
                    rewixUpdateCart(container, true);
                    window.location.href = '/current/cart';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.btn-wishlist-remove-all').on('click', function(event) {
        event.preventDefault();
        const currentTableSelector = $(this).attr('data-product-id');
        const currentTable = $(currentTableSelector);
        const inputs = currentTable.find('.i-number');

        let data = {};
        inputs.each(function () {
            data[$(this).attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart?wishlist=true',
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/profile/wishlist';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.wishlist-remove-all-products').on('click', function(event) {

        if (confirm(msg['MSG_DELETEWISHLIST'])) {

            var products = document.getElementsByClassName('wishlist-update').length;
            var i = 0;
            $('.btn-wishlist-remove-all').each(function() {
                event.preventDefault();
                var currentTable = $(this).attr('data-product-id');
                var inputs = $(currentTable).find('.i-number');
                var data = {};

                inputs.each(function () {
                    data[$(this).attr('name')] = 0;
                });

                $.ajax({
                    type: 'POST',
                    url: '/restful/shopping/public/checkout/addtocart?wishlist=true',
                    data: data,
                    dataType: 'json',
                    success: function(data) {
                        i++;
                        if (i == products) {
                            window.location.href = '/current/profile/wishlist';
                        }
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    }
                });
            });

        }

    });

    $('.btn-wishlist-to-cart').on('click', function(event) {
        event.preventDefault();
        var inputs = $('.wishlist-select');
        var dataAddToCart = {};
        var dataRemoveWishList = {};

        inputs.each(function () {
            if ($(this).prop("checked")) {
                dataAddToCart[$(this).attr('name').replace("select", "qty")] = $(this).val();
                dataRemoveWishList[$(this).attr('name').replace("select", "qty")] = $(this).data('qtyremaining');
            }
        });
        if (Object.keys(dataAddToCart).length > 0){
            $.ajax({
                type: 'POST',
                url: '/restful/shopping/public/checkout/addtocart',
                data: dataAddToCart,
                dataType: 'json',
                success: function(data) {
                    $.ajax({
                        type: 'POST',
                        url: '/restful/shopping/public/checkout/addtocart?wishlist=true',
                        data: dataRemoveWishList,
                        success: function(data) {
                            window.location.href = '/current/cart';
                        },
                        error: function(xhr, textStatus, errorThrown) {
                            showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                        }
                    });
                },
                error: function(xhr, textStatus, errorThrown) {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            });
        }else{
            showMessageBox('danger', msg['MSG_SELECT_PRODUCTS']);
        }
    });


    // Coupon form
    $(".burn-coupon-form").submit(function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

});