$(document).ready(function() {

    if (sessionStorage.getItem('holiday') != '1' && $('#holiday-reveal').length > 0) {
      sessionStorage.setItem('holiday', '1');
      $('#holiday-reveal').foundation('open');
    }

    $('#close-holiday-popup').on('click', function () {
      $('#holiday-reveal').foundation('close');
    });

    if (document.getElementById('user-logged') != undefined) {

    if (document.getElementById('user-logged').value == "logged") {
      $('.not_logged').addClass('hide');
    } else {
      $('.logged').addClass('hide');
    }

    }

    //document.getElementsByClassName('home-2col-space')[0].scrollIntoView({behavior: "smooth", block: "start"})

    if (!((parent != undefined) && (parent.window != undefined) && (parent.window.it != undefined))) {

    if (Foundation.MediaQuery.atLeast('large')) {

      $('#slider_home').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        appendDots: '.slider_block',
        speed: 3000,
        swipe: true,
        adaptiveHeight: true,
        fade: true,
        cssEase: 'linear'
      });

    } else {

      $('#slider_home').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        speed: 3000,
        swipe: true,
        adaptiveHeight: true,
        fade: true,
        appendDots: '#slider_home',
        cssEase: 'linear'
      });

    }

  }



    /*$('.slider_btn').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        swipe: false,
        fade: true,
        cssEase: 'linear'
      });

      if (Foundation.MediaQuery.atLeast('large')) {

        $('.slider_text').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          swipe: false,
          arrows: false,
          asNavFor: '.slider_full__image, .slider_btn',
          adaptiveHeight: true,
          speed: 500,
          fade: true,
          cssEase: 'linear'
        });

      } else {

        $('.slider_text').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          swipe: false,
          arrows: false,
          asNavFor: '.slider_full__image, .slider_btn',
          adaptiveHeight: true,
          appendDots: '#slider-home-page',
          speed: 500,
          fade: true,
          cssEase: 'linear'
        });

      }*/

      if (Foundation.MediaQuery.atLeast('large')) {

        $('.wide').removeClass('hide');
        $('.mid_small').addClass('hide');

      } else {

        $('.wide').addClass('hide');
        $('.mid_small').removeClass('hide');

      }

      $('.slider_full__image').removeClass('hide');

      $('#slider_block').removeClass('hide');

      $('#slider_btn').removeClass('hide');

});