$(document).ready(function() {
    'use strict';

    var currentCountry = $( "#country" ).find('option:selected').data('eu');

    if (currentCountry == 'EU' || currentCountry == 'IT' ) {
        $('#cfpiva-div').removeClass('hide');
        $('#cfpiva')
            .attr('required','require')
            .prop('disabled', false);
        $('#fiscal_code-div').removeClass('hide');
    }
    else {
        $('#cfpiva-div').addClass('hide');
        $('#cfpiva')
            .removeAttr('required')
            .prop('disabled', true);
        $('#fiscal_code-div').addClass('hide');
    }

    $('select[name*="country"]').on('change', function() {
        var selected = $(this).find('option:selected');
        var requiredVat = selected.data('eu'); 

        if (requiredVat == 'EU' || requiredVat == 'IT' ) {
            $('#cfpiva-div').removeClass('hide');
            $('#cfpiva')
                .attr('required','required')
                .prop('disabled', false)
                .val("");
            
            if (requiredVat == 'IT') {
                $('.user-update-sdi-form').removeClass('hide');

                $('#fiscal_code-div').removeClass('hide');
                $('#fiscal_code')
                    .prop('disabled', false)
                    .val("");
            }
            else {
                $('.user-update-sdi-form').addClass('hide');

                $('#fiscal_code-div').addClass('hide');
                $('#fiscal_code')
                    .prop('disabled', true)
                    .val("");
            }
        }
        else {
            $('#cfpiva-div').addClass('hide');
            $('#cfpiva')
                .removeAttr('required')
                .prop('disabled', true)
                .val("");

            $('#fiscal_code-div').addClass('hide');
            $('#fiscal_code')
                .prop('disabled', true)
                .val("");
            
            $('.user-update-sdi-form').addClass('hide')
        }

    });

    function submitUserUpdateForm(form) {
        const $form= $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                    setTimeout(function(){ window.location.reload(true); }, 500);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-sdi-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function(form) {
            if ($('#sdi').val() != '' || $('#pec').val() != '') {
                submitUserUpdateForm(form);
            } else {
                showMessageBox('danger', msg['MSG_MISSING_PEC_SDI']);
            }
        }
    });

    $('#password-change-form').submit(function(event) {
        event.preventDefault();
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test($("#password").val())) {
            showMessageBox('danger', msg['passwordnotcomplex']);
        } else if($("#password").val() !== $("#password_confirm").val()) {
            showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
        } else {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $(this).find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    } else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                complete: function (data) {
                    $(this).find(':input').prop('disabled', false);
                }
            });
        }
    });

    $('#profile-consent-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });
});